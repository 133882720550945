import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Button, Heading } from "@hearsaycorp/hearsay-design-system";
import { Logo } from "../common";
import { LandingTemplate } from "../common/LandingTemplate";
import { useTranslationContext } from "../context";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDataContext } from "../context/DataProvider";
import { AuthErrorAlert } from "./AuthAlerts";
import { useAuthError } from "../hooks";
export const LandingOrg = () => {
    const { LOG_IN_ORG_HEADING, BACK_BUTTON } = useTranslationContext();
    const [searchParams] = useSearchParams();
    const { authErrorCode } = useAuthError({ searchParams });
    const { authProviders, fetchAuthProviders, authLoading, orgName } = useDataContext();
    const { id: orgId } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        fetchAuthProviders({ orgId });
    }, []);
    return (_jsxs(LandingTemplate, { children: [_jsxs("header", { className: "login-browser-client__header", children: [_jsx(Logo, {}), !authLoading && orgName ? (_jsx(Heading, { size: Heading.CONSTANTS.HEADING_SIZE.H1, sizeAs: Heading.CONSTANTS.HEADING_SIZE.H2, children: _jsx("span", { dangerouslySetInnerHTML: { __html: LOG_IN_ORG_HEADING(orgName) } }) })) : (_jsx("div", { className: "hds-block-loader hds-block-loader--title" })), authErrorCode && _jsx(AuthErrorAlert, { authErrorCode: authErrorCode })] }), _jsx("section", { className: "login-browser-client__content login-browser-client__content--org", children: _jsx("ul", { children: !authLoading
                        ? authProviders.map(({ id, url, label, iconName, iconColor, dataAttributes }) => (_jsx("li", { className: "login-browser-client__auth-provider", children: _jsx(Button, { href: url, text: label, iconName: iconName, iconColor: iconColor, dataAttributes: dataAttributes }) }, id)))
                        : new Array(2)
                            .fill(null)
                            .map((_, i) => (_jsx("li", { className: "hds-block-loader hds-block-loader--provider" }, i))) }) }), _jsx("section", { className: "login-browser-client__back-btn", children: _jsx(Button, { iconName: Button.CONSTANTS.ICON.ARROW_LEFT, onClick: () => navigate("..", { relative: "path" }), text: BACK_BUTTON, theme: Button.CONSTANTS.BUTTON_THEME.LINK, dataAttributes: { "data-metrics-tracking": "login_method_back" } }) })] }));
};
