import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { makeCopyright } from "../../utils";
import { useTranslationContext } from "../context";
import { EXTERNAL_LINKS } from "../constants";
export const Link = ({ href, label, metricsTracking }) => (_jsx("a", { className: "hds-link", href: href, target: "_blank", rel: "noopener noreferrer", "data-metrics-tracking": metricsTracking, children: label }));
export const LandingTemplate = ({ children }) => {
    const { TROUBLE_LOGGING_IN, CONTACT_SUPPORT, GENERAL_QUESTIONS, LEARN_MORE, PRIVACY_POLICY, } = useTranslationContext();
    return (_jsxs("div", { className: "login-browser-client", children: [_jsx("main", { className: "login-browser-client__main", children: _jsxs("section", { children: [children, _jsxs("footer", { className: "login-browser-client__footer", children: [_jsxs("ul", { children: [_jsxs("li", { children: [TROUBLE_LOGGING_IN, _jsx(Link, { href: EXTERNAL_LINKS.SUPPORT, label: CONTACT_SUPPORT, metricsTracking: "login_contact_support" })] }), _jsxs("li", { children: [GENERAL_QUESTIONS, _jsx(Link, { href: EXTERNAL_LINKS.LEARN_MORE, label: LEARN_MORE, metricsTracking: "login_learn_more" })] })] }), _jsxs("div", { children: [useMemo(makeCopyright, []), _jsx(Link, { href: EXTERNAL_LINKS.PRIVACY_POLICY, label: PRIVACY_POLICY, metricsTracking: "login_privacy_policy" })] })] })] }) }), _jsx("section", { className: "login-browser-client__side-flair" })] }));
};
