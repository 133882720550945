import { useState, useEffect } from "react";
const ERROR_CODE = "error-code";
export const useAuthError = ({ searchParams }) => {
    const [authErrorCode, setAuthErrorCode] = useState(null);
    useEffect(() => {
        if (searchParams.has(ERROR_CODE)) {
            setAuthErrorCode(searchParams.get(ERROR_CODE));
        }
    }, []);
    return {
        authErrorCode,
    };
};
