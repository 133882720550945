export default {
  "de-DE": {
    translation: {
      BACK_BUTTON: "Zurück",
      CONTACT_SUPPORT: "Kontaktieren Sie den Support",
      CONTINUE_LABEL: "Weiter",
      EMAIL: "E-Mail",
      EMAIL_PLACEHOLDER: "Geben sie ihre E-Mail-Adresse ein",
      EMAIL_SUCCESS:
        "<strong>Die Anmeldeanweisungen wurden an {{email}} gesendet</strong>",
      ERROR_CODE: "Fehlercode:",
      GENERAL_QUESTIONS: "Allgemeine Fragen?",
      GENERIC_FAILURE:
        "<strong>Etwas ist schiefgelaufen.</strong> Versuchen Sie es erneut und wenden Sie sich an den Support, falls das Problem weiterhin auftritt.",
      LANDING_SUBTITLE: "Melden Sie sich bei Ihrem Konto an.",
      LANDING_TITLE: "Willkommen zurück",
      LEARN_MORE: "Mehr erfahren",
      LOG_IN_FACEBOOK: "Mit Facebook einloggen",
      LOG_IN_LINKEDIN: "Login mit LinkedIn",
      LOG_IN_ORG_HEADING: "Melden Sie sich bei {{orgName}} an",
      LOG_IN_SSO: "Melden Sie sich mit SSO an",
      PRIVACY_POLICY: "Datenschutz-Bestimmungen",
      SIGN_UP_PROMPT:
        "Wenn Sie noch kein Konto haben, wenden Sie sich an Ihren Administrator, um sich anzumelden",
      TROUBLE_LOGGING_IN: "Probleme beim Anmelden?",
    },
  },
  "en-US": {
    translation: {
      BACK_BUTTON: "Go back",
      CONTACT_SUPPORT: "Contact support",
      CONTINUE_LABEL: "Continue",
      EMAIL: "Email",
      EMAIL_PLACEHOLDER: "Enter your Email",
      EMAIL_SUCCESS: "<strong>Login instructions sent</strong> to {{email}}",
      ERROR_CODE: "Error code:",
      GENERAL_QUESTIONS: "General questions?",
      GENERIC_FAILURE:
        "<strong>Something went wrong.</strong> Try again and contact support if this keeps happening.",
      LANDING_SUBTITLE: "Log in to your account.",
      LANDING_TITLE: "Welcome back",
      LEARN_MORE: "Learn more",
      LOG_IN_FACEBOOK: "Log in with Facebook",
      LOG_IN_LINKEDIN: "Log in with LinkedIn",
      LOG_IN_ORG_HEADING: "Log in to {{orgName}}",
      LOG_IN_SSO: "Log in with SSO",
      PRIVACY_POLICY: "Privacy Policy",
      SIGN_UP_PROMPT:
        "If you don't have an account, contact your admin to sign up",
      TROUBLE_LOGGING_IN: "Trouble logging in?",
    },
  },
  "es-ES": {
    translation: {
      BACK_BUTTON: "Volver",
      CONTACT_SUPPORT: "Póngase en contacto con el servicio de asistencia",
      CONTINUE_LABEL: "Continuar",
      EMAIL: "Correo electrónico",
      EMAIL_PLACEHOLDER: "Introduzca su correo electrónico",
      EMAIL_SUCCESS:
        "<strong>Instrucciones de inicio de sesión enviadas</strong> a {{email}}",
      ERROR_CODE: "Código de error:",
      GENERAL_QUESTIONS: "¿Preguntas generales?",
      GENERIC_FAILURE:
        "<strong>Algo ha salido mal.</strong> Inténtalo de nuevo y, si el error persiste, póngase en contacto con el servicio de asistencia.",
      LANDING_SUBTITLE: "Inicie sesión en su cuenta.",
      LANDING_TITLE: "¡Le damos la bienvenida de nuevo!",
      LEARN_MORE: "Conoce más",
      LOG_IN_FACEBOOK: "Iniciar sesión con Facebook",
      LOG_IN_LINKEDIN: "Iniciar sesión con LinkedIn",
      LOG_IN_ORG_HEADING: "Inicie sesión en {{orgName}}",
      LOG_IN_SSO: "Iniciar sesión con SSO",
      PRIVACY_POLICY: "Política de privacidad",
      SIGN_UP_PROMPT:
        "Si no tiene una cuenta, póngase en contacto con su administrador para registrarse",
      TROUBLE_LOGGING_IN: "¿Problemas para iniciar sesión?",
    },
  },
  "es-MX": {
    translation: {
      BACK_BUTTON: "Regresar",
      CONTACT_SUPPORT:
        "Póngase en contacto con el servicio de soporte técnico.",
      CONTINUE_LABEL: "Continuar",
      EMAIL: "Correo electrónico",
      EMAIL_PLACEHOLDER: "Ingrese el correo electrónico.",
      EMAIL_SUCCESS:
        "<strong>Las instrucciones para iniciar sesión se enviaron</strong> a {{email}}",
      ERROR_CODE: "Código de error:",
      GENERAL_QUESTIONS: "¿Preguntas generales?",
      GENERIC_FAILURE:
        "<strong>Algo salió mal.</strong> Intente nuevamente y póngase en contacto con el servicio de soporte técnico si el problema persiste.",
      LANDING_SUBTITLE: "Ingrese a su cuenta.",
      LANDING_TITLE: "Bienvenido nuevamente",
      LEARN_MORE: "Obtén más información",
      LOG_IN_FACEBOOK: "Iniciar sesión con Facebook",
      LOG_IN_LINKEDIN: "Iniciar sesión con LinkedIn",
      LOG_IN_ORG_HEADING: "Inicie sesión en {{orgName}}",
      LOG_IN_SSO: "Iniciar sesión con SSO",
      PRIVACY_POLICY: "Política de privacidad",
      SIGN_UP_PROMPT:
        "Si no posee una cuenta, comuníquese con el administrador para registrarse.",
      TROUBLE_LOGGING_IN: "¿Problemas para iniciar sesión?",
    },
  },
  "fr-CA": {
    translation: {
      BACK_BUTTON: "Retour",
      CONTACT_SUPPORT: "Contacter le service de soutien",
      CONTINUE_LABEL: "Continuer",
      EMAIL: "Courriel",
      EMAIL_PLACEHOLDER: "Entrer votre courriel",
      EMAIL_SUCCESS:
        "<strong>Instructions de connexion envoyées</strong> à {{email}}",
      ERROR_CODE: "Code d'erreur :",
      GENERAL_QUESTIONS: "Vous avez des questions générales ?",
      GENERIC_FAILURE:
        "<strong>Un problème est survenu.</strong> Réessayez et contactez le service de soutien s'il persiste.",
      LANDING_SUBTITLE: "Connectez-vous à votre compte.",
      LANDING_TITLE: "Bon retour",
      LEARN_MORE: "En savoir plus",
      LOG_IN_FACEBOOK: "Se connecter avec Facebook",
      LOG_IN_LINKEDIN: "Se connecter avec LinkedIn",
      LOG_IN_ORG_HEADING: "Connectez-vous à {{orgName}}",
      LOG_IN_SSO: "Connexion avec authentification unique",
      PRIVACY_POLICY: "Politique de confidentialité",
      SIGN_UP_PROMPT:
        "Si vous n'avez pas de compte, contactez votre administrateur pour vous inscrire",
      TROUBLE_LOGGING_IN: "Difficultés à vous connecter?",
    },
  },
  "fr-FR": {
    translation: {
      BACK_BUTTON: "Revenir en arrière",
      CONTACT_SUPPORT: "Contacter l'assistance",
      CONTINUE_LABEL: "Continuer",
      EMAIL: "Email",
      EMAIL_PLACEHOLDER: "Saisissez votre e-mail",
      EMAIL_SUCCESS:
        "<strong>Instructions de connexion envoyées</strong> à {{email}}",
      ERROR_CODE: "Code d'erreur :",
      GENERAL_QUESTIONS: "Vous avez des questions générales ?",
      GENERIC_FAILURE:
        "<strong>Une erreur s'est produite. </strong> Réessayez et contactez le service d'assistance si ce problème persiste.",
      LANDING_SUBTITLE: "Connectez-vous à votre compte.",
      LANDING_TITLE: "Bon retour parmi nous",
      LEARN_MORE: "En savoir plus",
      LOG_IN_FACEBOOK: "Se connecter avec Facebook",
      LOG_IN_LINKEDIN: "Se connecter avec LinkedIn",
      LOG_IN_ORG_HEADING: "Se connecter à {{orgName}}",
      LOG_IN_SSO: "Se connecter avec l'authentification unique (SSO)",
      PRIVACY_POLICY: "Politique de confidentialité",
      SIGN_UP_PROMPT:
        "Si vous ne possédez pas de compte, contactez votre administrateur afin de vous inscrire",
      TROUBLE_LOGGING_IN: "Vous ne parvenez pas à vous connecter ?",
    },
  },
  "hu-HU": {
    translation: {
      BACK_BUTTON: "Menjen vissza",
      CONTACT_SUPPORT: "Kapcsolatfelvétel az ügyfélszolgálattal",
      CONTINUE_LABEL: "Folytatás",
      EMAIL: "E-mail",
      EMAIL_PLACEHOLDER: "Írja be az e-mail-címét",
      EMAIL_SUCCESS:
        "<strong>A bejelentkezési utasítások elküldve</strong> a következő címre: {{email}}",
      ERROR_CODE: "Hibakód:",
      GENERAL_QUESTIONS: "Általános kérdései vannak?",
      GENERIC_FAILURE:
        "<strong>Valami hiba történt.</strong> Próbálja újra, és forduljon az ügyfélszolgálathoz, ha ez továbbra is fennáll.",
      LANDING_SUBTITLE: "Jelentkezzen be a fiókjába.",
      LANDING_TITLE: "Üdv. újra itt",
      LEARN_MORE: "További információ",
      LOG_IN_FACEBOOK: "Jelentkezz be facebookal",
      LOG_IN_LINKEDIN: "Bejelentkezés Linkedln-nel",
      LOG_IN_ORG_HEADING: "Bejelentkezés ide: {{orgName}}",
      LOG_IN_SSO: "Bejelentkezés SSO-val",
      PRIVACY_POLICY: "Adatvédelmi nyilatkozat",
      SIGN_UP_PROMPT:
        "Ha nincs fiókja, lépjen kapcsolatba a rendszergazdával a regisztrációhoz",
      TROUBLE_LOGGING_IN: "Probléma a bejelentkezéssel?",
    },
  },
  "it-IT": {
    translation: {
      BACK_BUTTON: "Indietro",
      CONTACT_SUPPORT: "Contatta l'assistenza",
      CONTINUE_LABEL: "Continua",
      EMAIL: "E-mail",
      EMAIL_PLACEHOLDER: "Inserisci la tua email",
      EMAIL_SUCCESS:
        "<strong>Istruzioni di accesso inviate</strong> a {{email}}",
      ERROR_CODE: "Codice errore:",
      GENERAL_QUESTIONS: "Domande generiche?",
      GENERIC_FAILURE:
        "<strong>Si è verificato un errore.</strong> Riprova e contatta l'assistenza se il problema persiste.",
      LANDING_SUBTITLE: "Accedi al tuo account.",
      LANDING_TITLE: "Piacere di rivederti",
      LEARN_MORE: "Per maggiori informazioni",
      LOG_IN_FACEBOOK: "Effettua il login con Facebook",
      LOG_IN_LINKEDIN: "Login con LinkedIn",
      LOG_IN_ORG_HEADING: "Accedi a {{orgName}}",
      LOG_IN_SSO: "Accedi con SSO",
      PRIVACY_POLICY: "Policy sulla privacy",
      SIGN_UP_PROMPT: "Se non hai un account, contatta l'admin per registrarti",
      TROUBLE_LOGGING_IN: "Problemi di accesso?",
    },
  },
  "pt-PT": {
    translation: {
      BACK_BUTTON: "Voltar",
      CONTACT_SUPPORT: "Contacte o suporte",
      CONTINUE_LABEL: "Continuar",
      EMAIL: "E-mail",
      EMAIL_PLACEHOLDER: "Digite o seu email",
      EMAIL_SUCCESS:
        "<strong>Instruções de acesso enviadas</strong> para {{email}}",
      ERROR_CODE: "Código de erro:",
      GENERAL_QUESTIONS: "Questões de âmbito geral?",
      GENERIC_FAILURE:
        "<strong>Algo correu mal.</strong> Tente novamente e entre em contacto com o suporte se isto continuar a acontecer.",
      LANDING_SUBTITLE: "Inicie sessão na sua conta.",
      LANDING_TITLE: "Bem-vindo de volta",
      LEARN_MORE: "Saiba mais",
      LOG_IN_FACEBOOK: "Iniciar sessão com o Facebook",
      LOG_IN_LINKEDIN: "Iniciar sessão com o LinkedIn",
      LOG_IN_ORG_HEADING: "Iniciar sessão em {{orgName}}",
      LOG_IN_SSO: "Iniciar sessão com SSO",
      PRIVACY_POLICY: "Política de Privacidade",
      SIGN_UP_PROMPT:
        "Se não tem uma conta, entre em contacto com o seu administrador para se registar",
      TROUBLE_LOGGING_IN: "Dificuldade em iniciar sessão?",
    },
  },
};
