import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
import { useAuthProviders, useEmailLookup, } from "../hooks";
const DataContext = createContext(null);
export const DataProvider = ({ csrfToken = "", children, }) => {
    const { loading: authLoading, authProviders, fetchAuthProviders, orgName, } = useAuthProviders();
    const { loading: emailLoading, fetchEmailLookup, emailAlertType, } = useEmailLookup({
        csrfToken,
    });
    const context = {
        authLoading,
        authProviders,
        emailLoading,
        emailAlertType,
        fetchEmailLookup,
        fetchAuthProviders,
        orgName,
    };
    return (_jsx(DataContext.Provider, { value: context, children: children }));
};
export const useDataContext = () => useContext(DataContext);
