import { jsx as _jsx } from "react/jsx-runtime";
import { hydrateRoot } from "react-dom/client";
import { I18nClient } from "@hearsaycorp/web-translations";
import { LoginBrowserClient } from "./view";
import { BrowserRouter } from "react-router-dom";
import languageResources from "../locale/strings";
export const initializeLoginBrowserClientApp = ({ language, csrfToken }) => {
    return new Promise((resolve, reject) => {
        I18nClient.init({ locale: language, languageResources })
            .then(() => {
            hydrateRoot(document.getElementById("ReactAppRootLogin"), _jsx(BrowserRouter, { children: _jsx(LoginBrowserClient, { csrfToken: csrfToken }) }));
        })
            .catch(reject);
    });
};
