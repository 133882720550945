export const EXTERNAL_LINKS = {
    PRIVACY_POLICY: "https://www.hearsaysystems.com/privacy-policy",
    LEARN_MORE: "https://hearsaysystems.com/",
    SUPPORT: "https://success.hearsaysocial.com/",
};
export var ALERT_STATES;
(function (ALERT_STATES) {
    ALERT_STATES["SUCCESS"] = "success";
    ALERT_STATES["FAILURE"] = "failure";
})(ALERT_STATES || (ALERT_STATES = {}));
