import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Button, Heading, TextInput } from "@hearsaycorp/hearsay-design-system";
import { Logo } from "../common";
import { useDataContext, useTranslationContext } from "../context";
import { LandingTemplate } from "../common/LandingTemplate";
import { EmailAlertSwitch } from "./EmailAlertSwitch";
export const Landing = () => {
    const { LANDING_TITLE, LANDING_SUBTITLE, EMAIL, EMAIL_PLACEHOLDER, CONTINUE_LABEL, SIGN_UP_PROMPT, } = useTranslationContext();
    const { emailLoading, emailAlertType, fetchEmailLookup } = useDataContext();
    const [email, setEmail] = React.useState("");
    const handleSetEmail = useCallback((e) => setEmail(e.target.value), [setEmail]);
    const handleEmailLookup = useCallback(() => {
        if (emailLoading || !email)
            return;
        fetchEmailLookup({
            email,
        });
    }, [email, setEmail]);
    const handleKeyDown = (e) => e.key === "Enter" && handleEmailLookup();
    return (_jsxs(LandingTemplate, { children: [_jsxs("header", { className: "login-browser-client__header", children: [_jsx(Logo, {}), _jsx(Heading, { size: Heading.CONSTANTS.HEADING_SIZE.H1, children: LANDING_TITLE }), _jsx("p", { className: "login-browser-client__subtitle", children: LANDING_SUBTITLE }), emailAlertType && (_jsx(EmailAlertSwitch, { email: email, emailAlertType: emailAlertType }))] }), _jsxs("section", { className: "login-browser-client__content", children: [_jsx(TextInput, { id: "email-input", disabled: emailLoading, labelText: EMAIL, placeholder: EMAIL_PLACEHOLDER, value: email, onChange: handleSetEmail, onKeyDown: handleKeyDown, test: "email-input" }), _jsx("div", { className: "login-browser-client__submit-email", children: _jsx(Button, { busy: emailLoading, onClick: handleEmailLookup, text: CONTINUE_LABEL, theme: Button.CONSTANTS.BUTTON_THEME.PRIMARY, dataAttributes: {
                                "data-metrics-tracking": "login_submit_email",
                            }, test: "submit-email-button" }) }), _jsx("footer", { children: _jsx("p", { children: SIGN_UP_PROMPT }) })] })] }));
};
