import { DEFAULT_HEADERS } from "../constants";
import { REQUEST_METHODS } from "./types";
const attachRequestOptions = (verb, options) => {
    const formattedOptions = {};
    formattedOptions.headers = DEFAULT_HEADERS;
    for (const key in options) {
        switch (key) {
            case "body":
                if (verb !== REQUEST_METHODS.GET &&
                    !(options.body instanceof URLSearchParams)) {
                    formattedOptions.body = JSON.stringify(options.body);
                }
                else {
                    formattedOptions[key] = options[key];
                }
                break;
            case "headers":
                formattedOptions.headers = Object.assign(Object.assign({}, formattedOptions.headers), options.headers);
                break;
            default:
                formattedOptions[key] = options[key];
        }
    }
    return formattedOptions;
};
const isJsonType = (contentType) => contentType && contentType.indexOf("application/json") > -1;
const fetchRequest = ({ url, options, success, fail, }) => {
    return fetch(url, options).then((response) => {
        const contentType = response.headers.get("content-type");
        const isJson = isJsonType(contentType);
        if (response.status >= 400 || !response.ok) {
            if (isJson) {
                return response.json().then(fail);
            }
        }
        if (response.status == 204) {
            return success(response);
        }
        if (isJson) {
            return response.json().then(success).catch(fail);
        }
        return response.text().then(success).catch(fail);
    }, fail);
};
export const fetchFactory = (httpVerbs) => {
    const methods = {};
    for (const verb in httpVerbs) {
        methods[httpVerbs[verb].toLowerCase()] = (endpoint, config) => {
            const httpVerb = httpVerbs[verb];
            return fetchRequest({
                url: `${endpoint}`,
                options: Object.assign(Object.assign({}, (config.options && attachRequestOptions(httpVerb, config.options))), { method: httpVerb }),
                success: config.success,
                fail: config.fail,
            });
        };
    }
    return methods;
};
