import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { ALERT_STATES } from "../constants";
import { useTranslationContext } from "../context";
import { Alert } from "@hearsaycorp/hearsay-design-system";
export const EmailAlertSwitch = ({ email, emailAlertType, }) => {
    const { GENERIC_FAILURE, EMAIL_SUCCESS } = useTranslationContext();
    return useMemo(() => {
        switch (emailAlertType) {
            case ALERT_STATES.SUCCESS:
                return (_jsx(Alert, { iconName: Alert.CONSTANTS.ICON.CIRCLE_CHECK, theme: Alert.CONSTANTS.ALERT_THEME.SUCCESS, children: _jsx("span", { dangerouslySetInnerHTML: { __html: EMAIL_SUCCESS(email) } }) }));
            case ALERT_STATES.FAILURE:
                return (_jsx(Alert, { iconName: Alert.CONSTANTS.ICON.CIRCLE_CLEAR, theme: Alert.CONSTANTS.ALERT_THEME.ERROR, children: _jsx("span", { dangerouslySetInnerHTML: { __html: GENERIC_FAILURE } }) }));
            default:
                return _jsx(_Fragment, {});
        }
    }, [emailAlertType]);
};
