import { useState } from "react";
import { fetchFactory, REQUEST_METHODS } from "../../api";
import { ALERT_STATES } from "../constants";
export const useEmailLookup = ({ csrfToken }) => {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const fetchEmailLookup = ({ email }) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("login_email_address", email);
        const data = new URLSearchParams();
        for (const pair of formData) {
            data.append(pair[0], pair[1]);
        }
        return fetchFactory([REQUEST_METHODS.POST]).post("/email_login_info/", {
            email,
            csrfToken,
            options: {
                body: data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-CSRFToken": csrfToken,
                },
            },
            success: () => {
                setAlert(ALERT_STATES.SUCCESS);
                setLoading(false);
            },
            fail: () => {
                setAlert(ALERT_STATES.FAILURE);
                setLoading(false);
            },
        });
    };
    return {
        emailAlertType: alert,
        loading,
        fetchEmailLookup,
    };
};
